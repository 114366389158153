<template>
  <div class="third-party-platform" ref="thirdPartyPlatform">
    <div class="function-buttons" ref="functionButtons">
      <el-button
        type="primary"
        size="small"
        @click="onAddPlatform"
        v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
        >新增</el-button
      >
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="84px"
      >
        <el-form-item label="平台标识：">
          <el-input
            v-model="form.identification"
            placeholder="请输入平台标识"
          ></el-input>
        </el-form-item>
        <el-form-item label="平台名称：">
          <el-input v-model="form.name" placeholder="请输入平台名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSearch"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column
        prop="identification"
        label="平台唯一标识"
        width="120"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="平台名称"
        width="120"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="receiveUrl"
        label="数据接收URL"
        width="280"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="encryptionMode" label="加密方式">
        <template slot-scope="scope">
          {{ encryptionModeObj[Number(scope.row.encryptionMode)] }}
        </template>
      </el-table-column>
      <el-table-column prop="checkCodeType" label="验证码类型" width="98">
        <template slot-scope="scope">
          {{ checkCodeTypeObj[Number(scope.row.checkCodeType)] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="headersJson"
        label="推送数据请求头内容"
        width="400"
      ></el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          {{ scope.row.status == 1 ? '禁用' : '启用' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="240">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            sort="danger"
            @click="onDisablePlatform(scope.row)"
            v-if="
              $store.state.menu.nowMenuList.indexOf('启用') >= 0 &&
              scope.row.status != 1
            "
            >禁用</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="onDisablePlatform(scope.row)"
            v-if="
              $store.state.menu.nowMenuList.indexOf('禁用') >= 0 &&
              scope.row.status == 1
            "
            style="color: #67c23a"
            >启用</el-button
          >
          <el-button
            @click="onAddPlatform(scope.row)"
            type="text"
            size="small"
            sort="primary"
            v-if="
              $store.state.menu.nowMenuList.indexOf('编辑') >= 0 &&
              scope.row.status != 1
            "
            >编辑</el-button
          >
          <el-button
            @click="orderdatePlatform(scope.row)"
            type="text"
            size="small"
            style="color: #007aff"
            v-if="
              $store.state.menu.nowMenuList.indexOf('订阅数据项') >= 0 &&
              scope.row.status != 1
            "
            >订阅数据项</el-button
          >
          <el-button
            @click="pushLine(scope.row)"
            type="text"
            size="small"
            style="color: #007aff"
            v-if="$store.state.menu.nowMenuList.indexOf('推送线路') >= 0"
            >推送线路</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增、编辑 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      @close="onDialogClose"
      width="30%"
    >
      <div class="dialog-body">
        <el-form
          ref="addForms"
          class="dialog-form-edit"
          :model="addForm"
          :rules="rules"
          label-position="left"
          label-width="140px"
        >
          <el-form-item label="平台标识：" prop="identification">
            <el-input
              v-model="addForm.identification"
              :disabled="isEidt ? true : false"
            ></el-input>
          </el-form-item>
          <el-form-item label="平台名称：" prop="name">
            <el-input v-model="addForm.name"></el-input>
          </el-form-item>
          <el-form-item label="数据接收URL：" prop="receiveUrl">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.receiveUrl"
            ></el-input>
          </el-form-item>
          <el-form-item label="加密方式：" prop="encryptionMode">
            <el-select clearable v-model.trim="addForm.encryptionMode">
              <el-option
                v-for="item in encryptionModeList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="验证码类型：" prop="checkCodeType">
            <el-select clearable v-model.trim="addForm.checkCodeType">
              <el-option
                v-for="item in checkCodeTypeList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="推送数据请求头：" prop="headersJson">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.headersJson"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态：" prop="status">
            <el-select clearable v-model.trim="addForm.status">
              <el-option
                v-for="item in statusList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="onDialogClose"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="editLoading"
          @click="onSure"
          >确认</el-button
        >
      </span>
    </el-dialog>
    <!-- 数据订阅项 -->
    <el-dialog
      title="数据订阅"
      :visible.sync="dialogOrderData"
      @close="orderDialogClose"
      width="60%"
    >
      <div class="oerder-data">
        <el-transfer
          style="text-align: left; display: inline-block"
          filterable
          :filter-method="filterMethod"
          :render-content="renderFunc"
          filter-placeholder="请输入数据项关键字"
          :titles="['可选数据项', '已配置数据项']"
          v-model="value"
          :data="orderData"
        ></el-transfer>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="orderDialogClose"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="orderLoading"
          @click="onSave"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 推送线路 -->
    <el-dialog
      title="线路推送"
      v-if="dialogPushLines"
      :visible.sync="dialogPushLines"
      @close="dialogPushLines = false"
      width="60%"
    >
      <div class="oerder-data">
        <el-transfer
          style="text-align: left; display: inline-block"
          filterable
          :filter-method="filterMethodLine"
          :render-content="renderFunc"
          filter-placeholder="请输入关键字"
          :titles="['可选数据项', '已配置数据项']"
          v-model="linesValue"
          :data="linesData"
        ></el-transfer>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogPushLines = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="pushLoading"
          @click="onSaveLinesPush"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { formatDict, checkJSON } from "@/common/utils/index";
import {
  queryplatformList,
  platformUpdateStatus,
  savePlatform,
  querySubscribeList,
  saveSubscribe,
  queryBindLineList,
  saveBindLine,
} from '@/api/lib/api.js'
export default {
  data () {
    return {
      form: {
        identification: '',
        name: '',
      },
      tableHeight: 0,
      tableData: [],
      //0 AES 1RSA
      encryptionModeList: [
        {
          dictCode: '1',
          dictValue: 'RSA'
        },
        {
          dictCode: '0',
          dictValue: 'AES'
        }
      ],
      encryptionModeObj: {},
      //0 无 1静态验证 2动态验证
      checkCodeTypeList: [
        {
          dictCode: '0',
          dictValue: '无'
        },
        {
          dictCode: '1',
          dictValue: '静态验证'
        },
        {
          dictCode: '2',
          dictValue: '动态验证'
        }
      ],
      checkCodeTypeObj: {},
      statusList: [
        {
          dictCode: '0',
          dictValue: '启用'
        },
        {
          dictCode: '1',
          dictValue: '禁用'
        }
      ],
      title: '新增',
      dialogVisible: false,
      addForm: {
        identification: '',
        name: '',
        receiveUrl: '',
        encryptionMode: '0',
        checkCodeType: '0',
        headersJson: '',
        status: '0'
      },
      editLoading: false,
      isEidt: null,
      rules: {
        identification: [
          { required: true, message: "请输入平台标识", trigger: "blur" }
        ],
        name: [
          { required: true, message: "请输入平台名称", trigger: "blur" }
        ],
        receiveUrl: [
          { required: true, message: "请输入数据接收URL", trigger: "blur" },
        ],
        encryptionMode: [
          { required: true, message: "请选择加密方式", trigger: "blur" },
        ],
        checkCodeType: [
          { required: true, message: "请选择验证码类型", trigger: "blur" },
        ],
        headersJson: [
          { required: true, message: "请输入推送数据请求头内容", trigger: "blur" },
          { validator: checkJSON, trigger: "blur" }
        ],
        status: [
          { required: true, message: "请选择验状态", trigger: "blur" },
        ],
      },
      //数据订阅
      dialogOrderData: false,
      orderLoading: false,
      value: [],
      orderData: [],
      dialogPushLines: [],
      linesValue: [],
      linesData: [],
      dialogPushLines: false,
      platformId: null,
      pushLoading:false,
    }
  },
  created () {
    this.encryptionModeObj = formatDict(this.encryptionModeList);
    this.checkCodeTypeObj = formatDict(this.checkCodeTypeList);
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      this.onSearch();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.thirdPartyPlatform.clientHeight;
      const buttonsHeight = this.$refs.functionButtons.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        16;
    },
    onSearch () {
      this.getListFun();
    },
    getListFun () {
      queryplatformList(this.form).then(res => {
        if (res.code === 1000) {
          this.tableData = res.data;
        }
      })
    },
    onAddPlatform (row) {
      this.dialogVisible = true;
      this.title = row?.id ? '编辑' : '新增';
      this.isEidt = row?.id;
      if (row?.id) {
        this.addForm = { ...row };
        this.addForm.encryptionMode = row.encryptionMode.toString();
        this.addForm.checkCodeType = row.checkCodeType.toString();
        this.addForm.status = row.status.toString();
      }
    },
    onDialogClose () {
      this.dialogVisible = false;
      this.isEidt = null;
      this.addForm = {
        identification: '',
        name: '',
        receiveUrl: '',
        encryptionMode: '0',
        checkCodeType: '0',
        headersJson: '',
        status: '0'
      }
    },
    orderDialogClose () {
      this.dialogOrderData = false;
    },
    async orderdatePlatform (row) {
      this.dialogOrderData = true;
      this.platformId = row.id;
      const res = await querySubscribeList({ id: row.id });
      if (res.code === 1000) {
        const { subscribe = [], unSubscribe = [] } = res.data;
        const all = [...subscribe, ...unSubscribe];
        this.orderData = all.map(item => ({
          key: item.id,
          label: item.businessName
        }))
        this.value = subscribe.map(item => item.id);
      }
    },
    pushLine (row) { 
      this.platformId = row.id;    
      queryBindLineList({ id: row.id }).then(res => {
        if (res.code === 1000) {
          const { bindLines = [], unBindLines = [] } = res.data;
          const all = [...bindLines, ...unBindLines];
          this.linesData = all.map(item => ({
            key: item.companyId,
            label: item.lineName
          }))
          this.linesValue = bindLines.map(item=>item.companyId);
          this.dialogPushLines = true;
        }else{
          this.$message.warning(res.msg);
        }
      })
    },
    onSaveLinesPush(){
      this.pushLoading = true;
      saveBindLine({
        platformId:this.platformId,
        lineIds:this.linesValue
      }).then(res=>{
        if (res.code === 1000) {
          this.$message.success(res.msg)
          this.dialogPushLines = false;
          this.onSearch();
        } else {
          this.$message.error(res.msg);
        }
        this.pushLoading = false;
      })
    },
    filterMethod (query, item) {
      if (query) {
        return item.key.split(query).length > 1;
      } else {
        return true;
      }
    },
    renderFunc (h, option) {
      return <span>{option.label}</span>
    },
    filterMethodLine (query, item) {
      if (query) {
        return item.label.split(query).length > 1;
      } else {
        return true;
      }
    },
    renderFuncLine (h, option) {
      return <span>{option.label}</span>
    },
    onSave () {
      const data = {
        platformId: this.platformId,
        dataCategoryIdList: this.value
      }
      saveSubscribe(data).then(res => {
        if (res.code === 1000) {
          this.$message.success(res.msg)
          this.orderDialogClose();
          this.onSearch();
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    //  禁用|启用 平台
    onDisablePlatform (row) {
      if (row.status != 1) {
        const h = this.$createElement;
        this.$msgbox({
          title: "消息",
          type: "warning",
          message: h("p", null, [
            h("span", null, "确定禁用平台 "),
            h("span", { style: "color: #059370" }, row.name),
            h("span", null, " ？")
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        })
          .then(() => {
            platformUpdateStatus({ id: row.id, status: '1' }).then(res => {
              if (res.code === 1000) {
                this.$message.success("禁用成功");
                row.status = "1";
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              showClose: true,
              message: "已取消禁用"
            });
          });
      } else {
        platformUpdateStatus({ id: row.id, status: '0' }).then(res => {
          if (res.code === 1000) {
            this.$message.success("启用成功");
            row.status = '0';
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    onSure () {
      let data = {}
      if (this.isEidt) {
        data = { ...this.addForm, id: this.isEidt }
      } else {
        data = { ...this.addForm }
      }
      this.$refs.addForms.validate(valid => {
        if (valid) {
          this.editLoading = true
          savePlatform(data).then(res => {
            if (res.code === 1000) {
              this.$message.success(res.msg)
              this.onDialogClose();
              this.onSearch();
            } else {
              this.$message.error(res.msg);
            }
            this.editLoading = false;
          })
        }
      })

    }
  }
}
</script>
<style lang="scss" scoped>
.third-party-platform {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
}
.dialog-form-edit {
  padding: 0 10%;
  .el-select {
    width: 100% !important;
  }
}
.dialog-body {
  max-height: 60vh;
  overflow-y: auto;
}
.oerder-data {
  text-align: center;
  padding: 20px;
}
/deep/.el-transfer-panel {
  width: max-content;
}
</style>